<template>
    <div class="background-filter">
        <progressive-background v-bind:src="src"
                                v-bind:placeholder="placeholder">
        </progressive-background>
    </div>
</template>

<script>
    export default {
        name: "BackgroundFilter",
        props: {
            color: {
                type: String,
                required: true
            },
            src: {
                type: String,
                required: true
            },
            placeholder: {
                type: String,
                required: true
            }
        },
        data() {
            return {}
        }
    }
</script>

<style lang="scss" scoped>
    .background-filter {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        background-color: rgba(225, 225, 225, 1);

        z-index: 0;
    }
</style>
