<template>
    <section class="treatment-series">
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <h3 class="label">{{ mode }} a Treatment Series</h3>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <div class="field has-addons">
                        <p class="control">
                            <a class="button"
                               v-bind:class="{ 'is-active': lang === 'en' }"
                               v-on:click="lang = 'en'">
                                <span>English</span>
                            </a>
                        </p>
                        <p class="control">
                            <a class="button"
                               v-bind:class="{ 'is-active': lang === 'zh-hk' }"
                               v-on:click="lang = 'zh-hk'">
                                <span>繁體中文</span>
                            </a>
                        </p>
                        <p class="control">
                            <a class="button"
                               v-bind:class="{ 'is-active': lang === 'zh-cn' }"
                               v-on:click="lang = 'zh-cn'">
                                <span>簡體中文</span>
                            </a>
                        </p>
                    </div>
                </div>
                <div class="level-item">
                    <button class="button"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                </div>
            </div>
        </div>

        <article class="row treatment position-relative">
            <div class="bg-wrapper position-absolute d-none d-md-block">
                <div class="container-fluid h-100">
                    <div class="row h-100">
                        <div class="col-md-7 d-flex flex-column py-4 h-100"></div>
                        <div class="col-md-5 cover h-100"
                             v-bind:style="{ 'background-image': `url(${ cover })` }">
                            <button class="button edit"
                                    v-on:click="setCover"
                                    v-on:mouseover="revealImageElement"
                                    v-on:mouseout="revealImageElement">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="image"></font-awesome-icon>
                                </span>
                                <span>Change Cover</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row m-0">
                    <div class="col-md-7 d-flex flex-column py-4 order-1 order-md-0">
                        <medium-editor class="editable"
                                       custom-tag="h1"
                                       v-bind:text="title[lang]"
                                       v-bind:options="Object.assign({ placeholder: { text: 'Title', } }, options)"
                                       v-on:edit="processTitleEditOperation">
                        </medium-editor>
                        <div class="row flex-1 treatments"></div>
                    </div>
                    <div class="col-md-5 d-none d-md-block"></div>
                    <div class="col-md-5 d-block d-md-none cover order-0 order-md-1"
                         v-bind:style="{ 'background-image': `url(${ cover })` }">
                        <button class="button edit"
                                v-on:click="setCover"
                                v-on:mouseover="revealImageElement"
                                v-on:mouseout="revealImageElement">
                            <span class="icon is-small">
                                <font-awesome-icon icon="image"></font-awesome-icon>
                            </span>
                            <span>Change Cover</span>
                        </button>
                    </div>
                </div>
            </div>
        </article>

        <b-modal scroll="keep" has-modal-card
                 v-bind:active.sync="isImageModalsActive.cover">
            <modal-set-image v-bind:file.sync="coverFile">
                Configure treatment series cover
            </modal-set-image>
        </b-modal>
    </section>
</template>

<script>
import editor from "vue2-medium-editor";
import BackgroundFilter from "../BackgroundFilter";
import ModalSetImage from "../modals/ModalSetImage";

export default {
    name: "TreatmentSeries",
    components: {
        ModalSetImage,
        BackgroundFilter,
        "medium-editor": editor
    },
    props: {
        seriesId: {
            type: String,
        },
    },
    data() {
        return {
            mode: "Create",
            loading: false,
            edited: false,
            lang: "en",
            options: {
                toolbar: {
                    buttons: ["bold", "italic", "underline", "strikethrough"]
                }
            },

            isImageModalsActive: {
                cover: false
            },

            _id: "",

            title: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },

            cover: "",
            coverFile: ""
        }
    },
    computed: {
        allowSave () {
            return this.title["en"] &&
                this.title["zh-hk"] &&
                this.title["zh-cn"] &&
                this.cover;
        },

        series () {
            return this.$store.state.salon.salon.series;
        },
    },
    methods: {
        setCover() {
            this.isImageModalsActive.cover = true;
        },

        revealImageElement(evt) {
            evt.currentTarget.parentElement.classList.toggle("active");
        },

        processTitleEditOperation(operation) {
            this.title[this.lang] = operation.api.origElements.innerHTML;
        },

        async save() {
            this.loading = true;

            if (this.mode === "Create") {
                let temp = {
                    title: this.title,
                    cover: this.coverFile
                };
                try {
                    await this.$store.dispatch("salon/addSeries", temp);
                } catch (e) {
                    console.error(e);
                    this.loading = false;
                    return;
                }
            }

            if (this.mode === "Update") {
                const temp = {
                    _id: this._id
                };

                if (this.title) {
                    temp.title = this.title
                };

                if (this.coverFile) {
                    temp.cover = this.coverFile
                };

                try {
                    await this.$store.dispatch("salon/updateSeries", temp);
                } catch (e) {
                    console.error(e);
                    this.loading = false;
                    return;
                }
            }

            this.loading = false;
            this.$router.push({
                name: "Salon-series"
            });
        }
    },
    mounted() {
        if (
            this.series.length > 0 &&
            this.seriesId
        ) {
            const target = this.series.find(
                series => series._id === this.seriesId
            );
            if (target) {
                this.mode = "Update";

                this._id = target._id;
                this.title = target.title;
                this.cover = `${this.$config.apiUrl}/${this.$config.apiVersion}/salon/series/image/${target.cover}`;
            }
        }
    },
    watch: {
        "$route.params.seriesId" (newVal, oldVal) {
            if (this.$store.state.salon.salon.series.length > 0) {
                let target = this.$store.state.salon.salon.series.find(series => series._id === newVal)
                if (target) {
                    this.mode = "Update";

                    this._id = target._id;
                    this.title = target.title;
                    this.cover = `${this.$config.apiUrl}/${this.$config.apiVersion}/salon/series/image/${target.cover}`;
                }
            }
        },

        "$store.state.salon.salon.series"(newVal, oldVal) {
            if (newVal.length > 0) {
                let target = newVal.find(series => series._id === this.$route.params.seriesId)
                if (target) {
                    this.mode = "Update"

                    this._id = target._id
                    this.title = target.title
                    this.cover = `${this.$config.apiUrl}/${this.$config.apiVersion}/salon/series/image/${target.cover}`
                }
            }
        },

        "coverFile"(newVal) {
            if (newVal instanceof File) {
                let reader = new FileReader()
                reader.addEventListener("load", () => {
                    this.cover = reader.result
                }, false)
                reader.readAsDataURL(newVal)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "~bootstrap/scss/bootstrap";

.treatment-series {
    background-color: rgba(255, 255, 255, 1);

    & > .level {
        background-color: rgba(255, 255, 255, 1);
        padding: 0.75rem 1rem;
        margin-bottom: 0;
        box-shadow: 0 0 1px rgba(125, 125, 125, 1);

        position: sticky;
        top: 0;
        z-index: 9;

        .label {
            font-size: 20px;
            margin-bottom: 0;
        }

        .control {
            margin-bottom: 0;

            & > .button {
                &.is-active {
                    background-color: #4a4a4a;
                    color: white;
                }
            }
        }
    }

    button {
        &.edit {
            position: absolute;
            z-index: 8;
        }
    }

    .editable {
        position: relative;
        border-bottom: dashed 2px #8c67ef;

        &:after {
            position: absolute;
            bottom: -24px;
            right: 0;
            content: "Click to edit";
            font-size: 14px;
            font-weight: normal;
            padding: 3px;
        }
    }

    .bg-wrapper {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .treatments {
        min-height: 300px;
    }

    .cover {
        box-shadow: 25px 0 50px 0 rgba(255, 255, 255, 1) inset;
        background-color: rgba(225, 225, 225, 1);
        background-repeat: no-repeat;
        background-size: cover;

        &.active {
            border: dashed 2px #ff1f4b;
        }
    }
}
</style>
